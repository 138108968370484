<template>
  <div :style="{ 'max-width': '500px', 'word-wrap': 'break-word' }" :id="id">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: ['text', 'id'],
};
</script>
