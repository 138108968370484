<template>
  <va-inner-loading :loading="loading" color="primary">
    <va-form ref="form">
      <div class="flex">
        <va-input
          class="mb-3"
          v-model="data.password"
          type="password"
          :label="$t('user.form.new_password')"
          :rules="[data.password.length > 0 || $t('form.error.field_required')]"
        />
        <va-input
          class="mb-3"
          v-model="data.passwordConfirmation"
          type="password"
          :label="$t('user.form.confirm_password')"
          :rules="[data.password == data.passwordConfirmation || $t('user.password_do_not_match')]"
        />
      </div>
      <div class="row justify--center">
        <va-button color="secondary" @click="handleSubmit" class="my-0">{{ $t('form.submit') }}</va-button>
      </div>
    </va-form>
  </va-inner-loading>
</template>

<script>
import { RESET_PASSWORD } from '@mdb/core';
import { useFormMutation } from '@/components/helpers/useFormMutation';
import { ref, reactive } from 'vue';
import AuthenticationService from '@/components/helpers/AuthenticationService';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
const toast = useToast();
import { useI18n } from 'vue-i18n';

export default {
  name: 'reset-password',
  setup() {
    const { t } = useI18n({ useScope: 'global' });
    const { loading, error, onDone, mutate } = useFormMutation(RESET_PASSWORD, false);

    const router = useRouter();
    const route = useRoute();

    // logout user on this page
    AuthenticationService.logout();

    const jwt = route.query?.jwt;

    if (!jwt) {
      router.push({ name: 'login' });
    }

    if (!AuthenticationService.hasValidJwt(jwt)) {
      toast(t('user.reset_link_has_expired'), {
        timeout: false,
        type: 'error',
        id: 'error-toast',
      });
      router.push({ name: 'recover-password' });
    }

    AuthenticationService.setCookie(jwt);

    const form = ref('reset-password');

    // form data
    const data = reactive({
      password: '',
      passwordConfirmation: '',
    });
    onDone(() => {
      router.push({ name: 'login' });
    });

    const handleSubmit = () => {
      if (form.value.validate()) {
        mutate({ data: data });
      }
    };
    return {
      form,
      data,
      handleSubmit,
      loading,
    };
  },
  methods: {
    onsubmit() {
      if (!this.password && this.password != this.confirmPassword) {
        this.errors = [];
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="scss"></style>
