<template>
    <div class="container">
        <div class="ml-2 mt-3">
            <p class="mb-4"></p>
            <p></p>
        </div>
        <va-button
        class="mt-3"
            size="large"
            data-cy="submit-button"
            @click="reloadPage"
            color="success"
            >lol
        </va-button>
    </div>
</template>

<script>
import Vue from 'vue';
import {router} from 'vue-router'
import { useI18n } from 'vue-i18n';

export default {
    setup() {
    // const { t } = useI18n({ useScope: 'global' });
    // return {
    //     l: t('property.mail_sent'),
    //     lol: t('property.add_text'),
    //     t: t("property.create_another")

    // }

    },
  methods: {
    reloadPage(){
      this.$router.go()
    }
  }
};
</script>

